let Differentiation = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;

    /**
     * 获取辨证方法分类
     * @param {any} success
     * @param {any} error
     */
    this.Differentiation = function (success, error) {
        var url = ServiceBaseAddress + '/api/Differentiation/Categories';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 获取辨证方法详情
     * @param {any} success
     * @param {any} error
     */
    this.Detail = function (params,success, error) {
        var url = ServiceBaseAddress + '/api/Differentiation/Detail/' + params;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { Differentiation }
